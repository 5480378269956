define("discourse/plugins/discourse-preset-topic-composer/discourse/templates/connectors/after-create-topic-button/new-topic-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (and this.currentUser (not this.createTopicDisabled))}}
    {{new-topic-dropdown category=this.category}}
  {{/if}}
  */
  {
    "id": "hgs/hmbP",
    "block": "[[[41,[28,[37,1],[[30,0,[\"currentUser\"]],[28,[37,2],[[30,0,[\"createTopicDisabled\"]]],null]],null],[[[1,\"  \"],[1,[28,[35,3],null,[[\"category\"],[[30,0,[\"category\"]]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"and\",\"not\",\"new-topic-dropdown\"]]",
    "moduleName": "discourse/plugins/discourse-preset-topic-composer/discourse/templates/connectors/after-create-topic-button/new-topic-dropdown.hbs",
    "isStrictMode": false
  });
});