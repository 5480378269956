define("discourse/plugins/discourse-preset-topic-composer/discourse/components/new-topic-dropdown", ["exports", "@ember/object", "@ember/owner", "@ember/service", "@ember-decorators/component", "discourse/models/composer", "select-kit/components/dropdown-select-box", "select-kit/components/select-kit"], function (_exports, _object, _owner, _service, _component, _composer, _dropdownSelectBox, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NewTopicDropdown = dt7948.c(class NewTopicDropdown extends _dropdownSelectBox.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "historyStore", [_service.service]))();
    #historyStore = (() => (dt7948.i(this, "historyStore"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "dropdownButtons", [_service.service]))();
    #dropdownButtons = (() => (dt7948.i(this, "dropdownButtons"), void 0))();
    get content() {
      return this.dropdownButtons.buttons;
    }
    onChange(selectedAction) {
      const composerController = (0, _owner.getOwner)(this).lookup("service:composer");
      let selectedButton = this.historyStore.get("newTopicButtonOptions");
      if (!selectedButton || selectedAction !== selectedButton.id) {
        const buttons = JSON.parse(this.siteSettings.button_types);
        selectedButton = buttons.find(button => button.id === selectedAction);
        this.historyStore.set("newTopicButtonOptions", selectedButton);
      }
      const selectedButtonCategoryId = selectedButton.categoryId > 0 ? selectedButton.categoryId : null;
      const tags = selectedButton.tags.split(/(?:,|\s)\s*/).filter(Boolean); // remove [''] from tags;
      const options = {
        action: _composer.default.CREATE_TOPIC,
        draftKey: _composer.default.NEW_TOPIC_KEY,
        categoryId: selectedButtonCategoryId ?? this.category?.id ?? null,
        tags
      };
      composerController.open(options);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onChange", [_object.action]))();
  }, [(0, _component.classNames)("new-topic-dropdown"), (0, _selectKit.selectKitOptions)({
    icon: "plus",
    showFullTitle: true,
    autoFilterable: false,
    filterable: false,
    showCaret: true,
    none: "topic.create"
  })]);
  var _default = _exports.default = NewTopicDropdown;
});