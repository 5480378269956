define("discourse/plugins/discourse-preset-topic-composer/discourse/templates/connectors/after-title-and-category/tag-group-combobox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.currentUser.can_create_topic}}
    {{#each this.tagGroupList as |tagGroupOption|}}
      {{#if tagGroupOption.multi}}
        {{tag-group-multiselect
          composer=@outletArgs.model
          tagGroupName=tagGroupOption.tagGroup
          required=tagGroupOption.required
          options=(hash
            translatedNone=(if
              tagGroupOption.required
              (concat "*" (i18n "composer.select") tagGroupOption.tagGroup)
              (concat (i18n "composer.select") tagGroupOption.tagGroup)
            )
          )
        }}
      {{else}}
        {{tag-group-combobox
          composer=@outletArgs.model
          tagGroupName=tagGroupOption.tagGroup
          required=tagGroupOption.required
          options=(hash
            translatedNone=(if
              tagGroupOption.required
              (concat "*" (i18n "composer.select") tagGroupOption.tagGroup)
              (concat (i18n "composer.select") tagGroupOption.tagGroup)
            )
          )
        }}
      {{/if}}
    {{/each}}
  {{/if}}
  */
  {
    "id": "DO9Ze4XI",
    "block": "[[[41,[30,0,[\"currentUser\",\"can_create_topic\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"tagGroupList\"]]],null]],null],null,[[[41,[30,1,[\"multi\"]],[[[1,\"      \"],[1,[28,[35,3],null,[[\"composer\",\"tagGroupName\",\"required\",\"options\"],[[30,2,[\"model\"]],[30,1,[\"tagGroup\"]],[30,1,[\"required\"]],[28,[37,4],null,[[\"translatedNone\"],[[52,[30,1,[\"required\"]],[28,[37,5],[\"*\",[28,[37,6],[\"composer.select\"],null],[30,1,[\"tagGroup\"]]],null],[28,[37,5],[[28,[37,6],[\"composer.select\"],null],[30,1,[\"tagGroup\"]]],null]]]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,7],null,[[\"composer\",\"tagGroupName\",\"required\",\"options\"],[[30,2,[\"model\"]],[30,1,[\"tagGroup\"]],[30,1,[\"required\"]],[28,[37,4],null,[[\"translatedNone\"],[[52,[30,1,[\"required\"]],[28,[37,5],[\"*\",[28,[37,6],[\"composer.select\"],null],[30,1,[\"tagGroup\"]]],null],[28,[37,5],[[28,[37,6],[\"composer.select\"],null],[30,1,[\"tagGroup\"]]],null]]]]]]]]],[1,\"\\n\"]],[]]]],[1]],null]],[]],null]],[\"tagGroupOption\",\"@outletArgs\"],false,[\"if\",\"each\",\"-track-array\",\"tag-group-multiselect\",\"hash\",\"concat\",\"i18n\",\"tag-group-combobox\"]]",
    "moduleName": "discourse/plugins/discourse-preset-topic-composer/discourse/templates/connectors/after-title-and-category/tag-group-combobox.hbs",
    "isStrictMode": false
  });
});