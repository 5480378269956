define("discourse/plugins/discourse-preset-topic-composer/discourse/connectors/composer-after-save-or-cancel/error-message", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ErrorMessage extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "shouldShowErrorMessage", [_tracking.tracked], function () {
      return false;
    }))();
    #shouldShowErrorMessage = (() => (dt7948.i(this, "shouldShowErrorMessage"), void 0))();
    constructor() {
      super(...arguments);
      this.appEvents.on("composer:preset-error", _ref => {
        let {
          isOk
        } = _ref;
        return this.shouldShowErrorMessage = !isOk;
      });
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldShowErrorMessage}}
          <i class="missing-input-error-message">
            {{i18n "dialog.error_message"}}
          </i>
        {{/if}}
      
    */
    {
      "id": "JHD34hPn",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldShowErrorMessage\"]],[[[1,\"      \"],[10,\"i\"],[14,0,\"missing-input-error-message\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"dialog.error_message\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-preset-topic-composer/discourse/connectors/composer-after-save-or-cancel/error-message.js",
      "scope": () => [_i18n.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ErrorMessage;
});