define("discourse/plugins/discourse-preset-topic-composer/discourse/connectors/after-title-and-category/tag-group-combobox", ["exports", "@ember/component", "@ember/service", "@ember-decorators/component"], function (_exports, _component, _service, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const TagGroupCombobox = dt7948.c(class TagGroupCombobox extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "historyStore", [_service.service]))();
    #historyStore = (() => (dt7948.i(this, "historyStore"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    init() {
      super.init(...arguments);
      const composerHTML = document.querySelector(".composer-fields");
      const selectedButton = this.historyStore.get("newTopicButtonOptions");
      const shouldShowTags = selectedButton?.showTags || false;
      if (!shouldShowTags) {
        composerHTML.classList.add("hide-tag");
      }
      this.appEvents.on("topic:created", () => {
        this.historyStore.set("newTopicButtonOptions", null);
      });
      this.appEvents.on("draft:destroyed", () => {
        this.historyStore.set("newTopicButtonOptions", null);
      });
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      const composerHTML = document.querySelector(".composer-fields");
      composerHTML.classList.remove("hide-tag");
    }
    get tagGroupList() {
      const selectedButton = this.historyStore.get("newTopicButtonOptions");
      return selectedButton?.tagGroups || [];
    }
  }, [(0, _component2.classNames)("tag-group_wrapper")]);
  var _default = _exports.default = TagGroupCombobox;
});