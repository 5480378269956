define("discourse/plugins/discourse-preset-topic-composer/discourse/components/tag-group-multiselect", ["exports", "select-kit/components/multi-select", "discourse/plugins/discourse-preset-topic-composer/discourse/components/tag-group-options"], function (_exports, _multiSelect, _tagGroupOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _multiSelect.default.extend({
    ..._tagGroupOptions.tagGroupOptions
  });
});