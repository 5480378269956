define("discourse/plugins/discourse-preset-topic-composer/discourse/components/tag-group-options", ["exports", "@glimmer/tracking", "@ember/service", "discourse/lib/ajax"], function (_exports, _tracking, _service, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tagGroupOptions = void 0;
  const tagGroupOptions = _exports.tagGroupOptions = {
    classNames: ["tag-group-input"],
    classNameBindings: ["isInvalid:tag-group-input--invalid"],
    siteSettings: (0, _service.service)(),
    historyStore: (0, _service.service)(),
    appEvents: (0, _service.service)(),
    content: (0, _tracking.tracked)({
      value: []
    }),
    value: (0, _tracking.tracked)({
      value: null
    }),
    tagGroupName: (0, _tracking.tracked)({
      value: null
    }),
    composer: (0, _tracking.tracked)({
      value: {}
    }),
    isInvalid: (0, _tracking.tracked)({
      value: false
    }),
    required: (0, _tracking.tracked)({
      value: false
    }),
    selectKitOptions: {
      filterable: true
    },
    init() {
      this._super(...arguments);
      (0, _ajax.ajax)(`/topic_composer/tag_by_tag_group/${encodeURIComponent(this.tagGroupName)}.json`).then(result => this.content = result.tags);
      this.composer.tag_groups[this.tagGroupName] = {
        component: this
      };
      const selectedButton = this.historyStore.get("newTopicButtonOptions");
      this.value = selectedButton.tagGroups.find(tagGroup => tagGroup?.value && tagGroup.tagGroup === this.tagGroupName)?.value;
      this.appEvents.on("composer:created-post", () => {
        delete this.composer.tags_to_add[this.tagGroupName];
        delete this.composer.tag_groups[this.tagGroupName];
      });
    },
    // used by initializer-composer-tag-groups.js
    validate() {
      if (this.required && !this.value) {
        this.isInvalid = true;
        return false;
      }
      return true;
    },
    actions: {
      onChange(tagId) {
        this.value = tagId;
        this.isInvalid = false;
        const getTagById = id => this.content.find(tag => tag.id === id);
        if (typeof tagId === "number") {
          this.composer.tags_to_add[this.tagGroupName] = [getTagById(tagId).name];
        } else {
          this.composer.tags_to_add[this.tagGroupName] = tagId.map(tag => getTagById(tag).name);
        }
        const selectedButton = this.historyStore.get("newTopicButtonOptions");
        for (const tagGroup of selectedButton.tagGroups) {
          if (tagGroup.tagGroup === this.tagGroupName) {
            tagGroup.value = this.value;
            break;
          }
        }
        this.historyStore.set("newTopicButtonOptions", selectedButton);
      }
    }
  };
});